<script setup lang="ts">
import { ArrowLeftIcon } from '@heroicons/vue/20/solid'
import { Link } from '@inertiajs/vue3'

const props = defineProps<{
    to: string
    route: any
}>()

defineOptions({
    inheritAttrs: false
})
</script>

<template>
    <Link
        :href="props.route"
        class="flex items-center gap-2 mb-6 group"
        v-bind="$attrs">
        <ArrowLeftIcon class="size-4 group-hover:-translate-x-1 transition-all delay-75 duration-700" />
        <p class="text-small">Back to {{ props.to }}</p>
    </Link>
</template>
